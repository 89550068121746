import React, { FC, useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import classNames from 'classnames';
import { GlobalContactForm } from '@phx-husky/global-contact-form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import UniversalImage from 'components/common/UniversalImage';
import { parseBanner } from 'components/Banner/parsers';

import { gtmService } from 'services/gtmService';

import './ContactUsPage.scss';
import './ContactUsPageOverride.scss';

interface IContactUsPageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: ContactUsTypes.IContactUsData;
}

const ContactUsPage: FC<IContactUsPageProps> = ({
  pageContext: { breadcrumbs },
  data: {
    contactUs: {
      contactUsBanner,
      contactUsContentBlocks,
      contactUsHeroImage,
      contactUsHeroImageAltText,
      contactFormLink,
      contactFormTitle,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      lang,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => {
  useEffect(() => {
    let timeoutId;
    function iframeMessageHandler(e: MessageEvent) {
      if (e.data.message === gtmService.iframeMessageFormSubmitted) {
        timeoutId = gtmService.emitGenerateLead(gtmService.formNames.contactUs);
      }
    }

    window.addEventListener('message', iframeMessageHandler);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('message', iframeMessageHandler);
    };
  }, []);

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div className="contact-us-page">
        <Banner
          {...parseBanner(contactUsBanner?.[0]?.properties)}
          bgBannerColor={
            contactUsBanner?.[0]?.properties?.pageBannerBannerBgColor?.[0]?.properties?.colorPicker
              ?.label || 'blue'
          }
          breadcrumbs={breadcrumbs}
        />

        <div className="rounded-container">
          <Container fluid>
            {contactFormLink && contactFormTitle ? (
              <Row>
                <Col sm={12}>
                  <GlobalContactForm
                    src={contactFormLink}
                    id="phx-global-form-gatsby"
                    title={contactFormTitle}
                    className="contact-form"
                  />
                </Col>
              </Row>
            ) : null}

            <Row>
              {contactUsContentBlocks?.length ? (
                <Col md={5} sm={12}>
                  <ul className="contact-us-list">
                    {contactUsContentBlocks.map((block: ContactUsTypes.IContactUsContentBlock) => (
                      <li
                        key={block.properties.contactUsBlockHeadline}
                        className={classNames('contact-us-list__block', {
                          'contact-us-list__block--with-icon': block.properties.contactUsBlockIcon,
                        })}
                      >
                        <div className="contact-us-list__title">
                          {block.properties.contactUsBlockIcon && (
                            <DangerouslySetInnerHtml
                              className="svg"
                              html={block.properties.contactUsBlockIcon.svg.content}
                            />
                          )}
                          <span>{block.properties.contactUsBlockHeadline}</span>
                        </div>

                        <div className="contact-us-list__htmlContent">
                          <DangerouslySetInnerHtml html={block.properties.contactUsBlockContent} />
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              ) : null}

              {contactUsHeroImage ? (
                <Col md={7} sm={12}>
                  <UniversalImage
                    imageAlt={contactUsHeroImageAltText}
                    img={contactUsHeroImage}
                    wrapperClassName="contact-us-hero-image"
                    objectFitData={{ objectFit: 'contain' }}
                  />
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    contactUs(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      contactUsBanner {
        properties {
          ...FragmentBanner
        }
      }
      contactUsContentBlocks {
        properties {
          contactUsBlockHeadline
          contactUsBlockContent
          contactUsBlockIcon {
            svg {
              content
            }
          }
        }
      }
      contactUsHeroImageAltText
      contactUsHeroImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 600, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 320, fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      contactFormLink
      contactFormTitle
    }
    pageLangs: allContactUs(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default ContactUsPage;
